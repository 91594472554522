<template>
  <div id="aboutUs" class="content">
    <div class="container">
      <div class="box-show-title">
        <el-row :gutter="20">
          <el-col :span="24" :md="14" class="text-l">
            <p class="title-page">
              ย่านนวัตกรรม
              <br />การแพทย์สวนดอก
            </p>
            <p class="sub-page">
              <!-- SMID ย่านนวัตกรรมการแพทย์สวนดอก หรือ Suandok Medical Innovation
              District (SMID) งานบริการและงานวิจัย ที่ดีที่สุดในภาคเหนือ
              บริการค้นหาโรงพยาบาล และคลินิกในย่าน -->
              ย่านนวัตกรรมการแพทย์สวนดอก (Suandok Medical Innovation
              District)<br />
              มุ่งสู่การเป็นศูนย์กลางแห่งนวัตกรรมการแพทย์ของภูมิภาคอย่างยั่งยืน
            </p>
          </el-col>
          <el-col :span="24" :md="10" class="text-r">
            <div class="box-size-img">
              <img src="../assets/image/aboutUs/about1.jpg" class="border20" alt />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="about-content">
      <div class="inside-content">
        <div class="img-out-container hidden-md">
          <img src="../assets/image/about22.png" alt />
        </div>
        <div class="container">
          <el-row :gutter="30">
            <el-col :span="24" :md="12" class="hidden-md">
              <span style="color: transparent">ไม่โชว์ข้อมูล</span>
            </el-col>
            <el-col :span="24" :md="12">
              <p class="title-inside">เกี่ยวกับ SMID</p>
              <p>
                <!-- ย่านนวัตกรรมการแพทย์สวนดอก ประกอบไปด้วยสถาบันการแพทย์
                สถาบันการศึกษา หน่วยวิจัยพื้นที่อยู่อาศัย ร้านอาหาร คาเฟ่
                Co-Working Space
                พร้อมต่อการพบปะแลกเปลี่ยนความคิดและนวัตกรรมใหม่ๆ
                นอกจากความพร้อมทางด้านพื้นที่แล้วยังพบว่า
                ภายในพื้นที่ยังเต็มไปด้วยผลงานวิจัยและนวัตกรรมที่พร้อมขยายผลสู่ตลาดโลกจํานวนมาก -->
                จากความก้าวหน้าทางเทคโนโลยีและการเพิ่มความสำคัญของการใช้นวัตกรรมในการแก้ปัญหาด้านสุขภาพ
                การจัดการนวัตกรรมที่สามารถเชื่อมโยงเครือข่ายในพื้นที่ที่มีการรวมตัวกันของผู้คน
                สถาบัน และบริษัท ให้เกิดกิจกรรมและมูลค่าทางเศรษฐกิจ
                จึงมีความสำคัญเพื่อผลักดันการเกิดนวัตกรรมใหม่ในเชิงการบริการ
                การเชื่อมโยงนวัตกรรมกับงานวิจัยและการถ่ายทอดนวัตกรรมในเชิงพาณิชย์
                ย่านนวัตกรรมทางการแพทย์ (Medical innovation district)
                จึงเป็นแนวคิดที่ตอบโจทย์ในการพัฒนาดังกล่าว
                โดยอาศัยข้อได้เปรียบในเชิงพื้นที่ (Area based innovation
                development)
              </p>
              <br />
              <p>
                <!-- นอกจากนี้ยังมีหน่วยงานต่างๆที่พร้อมต่อการเชื่อมต่อและแชร์ทรัพยากร
                อย่างเป็นระบบจากศักยภาพของพื้นที่จึงเป็นที่มาของโครงการในการพัฒนาพื้นที่ดังกล่าวให้เป็นพื้นที่ต้นแบบการพัฒนาและการลงทุนงานด้านนวัตกรรมการแพทย์ของประเทศ -->
                จังหวัดเชียงใหม่
                เป็นจังหวัดที่มีสถาบันการศึกษาและการให้บริการทางการแพทย์ที่โดดเด่นในระดับนานาชาติหลายภาคส่วน
                โดยในเชิงพื่นที่ของจังหวัดบริเวณย่านถนนสุเทพ หรือ ย่านสวนดอก
                ได้มีการกระจุกตัวกันของคณะวิทยาศาสตร์ทางการแพทย์ของมหาวิทยาลัยเชียงใหม่
                หน่วยให้บริการของสาธารณสุข โรงพยาบาลและคลินิกเอกชน บริษัท
                Startup ศูนย์นวัตกรรมทางการแพทย์
                ศูนย์วิจัยและพัฒนาผลิตภัณฑ์ทางการแพทย์ Co-working space และ
                กิจกรรมและการให้บริการทางการแพทย์ที่หลากหลาย
              </p>
              <br />
              <p>
                <!-- นอกจากนี้ยังมีหน่วยงานต่างๆที่พร้อมต่อการเชื่อมต่อและแชร์ทรัพยากร
                อย่างเป็นระบบจากศักยภาพของพื้นที่จึงเป็นที่มาของโครงการในการพัฒนาพื้นที่ดังกล่าวให้เป็นพื้นที่ต้นแบบการพัฒนาและการลงทุนงานด้านนวัตกรรมการแพทย์ของประเทศ -->
                ทาง สำนักงานนวัตกรรมแห่งชาติ (National Innovation Agency)
                คณะแพทยศาสตร์และ กลุ่มคณะวิทยาศาสตร์สุขภาพมหาวิทยาลัยเชียงใหม่
                และ อุทยานวิทยาศาสตร์และเทคโนโลยีภาคเหนือ ได้จัดตั้ง
                “ย่านนวัตกรรมการแพทย์สวนดอก” ขึ้น
                เพื่อวัตถุประสงค์ในการส่งเสริมการสร้างนวัตกรรมในพื้นที่ดังกล่าวขึ้น
              </p>
            </el-col>
          </el-row>
        </div>
        <div class="img-out-container hidden-web">
          <img src="../assets/image/about22.png" alt />
        </div>
      </div>
      <div class="inside-content">
        <div class="container">
          <div class="box-custom-column">
            <div class="tab-left tab-first">
              <p class="title-inside">
                <!-- เรามุ่งขับเคลื่อนนวัตกรรม
                <br />ทางการแพทย์ -->
                การขับเคลื่อนนวัตกรรมทางการแพทย์ของ SMID
              </p>
              <p class="set-width">
                <!-- พัฒนาพื้นที่และระบบนิเวศ (EcoSystem) ที่เอื้อต่อการ
                ศึกษาวิจัยพัฒนา -->
                เราพัฒนา 4 แกนหลักสำคัญ
                ในการขับเคลื่อนย่านนวัตกรรมทางการแพทย์สวนดอก
              </p>
              <br />
            </div>
          </div>
          <div class="box-custom-column">
            <div class="tab-right">
              <div class="box-card">
                <img
                  src="../assets/image/aboutUs/about3.jpg"
                  class="img-content"
                  alt
                />
                <div class="title-and-detail">
                  <div class="title-card">
                    <p class="number-list">1</p>
                    <!-- สนับสนุนและขยายโอกาส ทางการวิจัยแบบครบวงจร -->
                    To be medical service district
                  </div>
                  <div class="detail-card">
                    <!-- พัฒนาพื้นที่และระบบนิเวศ (EcoSystem) ที่เอื้อต่อการ
                    ศึกษาวิจัยพัฒนา และทดลองนวัตกรรมด้านการแพทย์
                    ภายในย่านให้ได้มาตรฐานและเป็นที่ยอมรับในระดับนานาชาติ
                    พร้อมเป็นศูนย์ กลางการบริการ (Medical Hub)
                    การศึกษาวิจัยพัฒนาและทดลองนวัตกรรม -->
                    เพื่อส่งเสริม พัฒนาและยกระดับงาน
                    การให้บริการทางสุขภาพแก่คนในพื้นที่
                    ผ่านกระบวนการจัดการที่เป็นระบบ
                    โดยมุ่งหวังให้ผู้รับบริการเข้าถึงการบริการทางการแพทย์ได้ง่าย
                    สะดวกสบายและมีคุณภาพ
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box-custom-column">
            <div class="tab-left">
              <div class="box-card">
                <img
                  src="../assets/image/aboutUs/about4.jpg"
                  class="img-content"
                  alt
                />
                <div class="title-and-detail">
                  <div class="title-card">
                    <p class="number-list">2</p>
                    <!-- สนับสนุนและขยายโอกาส ทางการวิจัยแบบครบวงจร -->
                    To be an special center of excellences and transdisciplinary
                    service
                  </div>
                  <div class="detail-card">
                    <!-- พัฒนาพื้นที่และระบบนิเวศ (EcoSystem) ที่เอื้อต่อการ
                    ศึกษาวิจัยพัฒนา และทดลองนวัตกรรมด้านการแพทย์
                    ภายในย่านให้ได้มาตรฐานและเป็นที่ยอมรับในระดับนานาชาติ
                    พร้อมเป็นศูนย์ กลางการบริการ (Medical Hub)
                    การศึกษาวิจัยพัฒนาและทดลองนวัตกรรม -->
                    เพื่อส่งเสริมการให้บริการทางการแพทย์เฉพาะทาง
                    และการเป็นศูนย์ความเป็นเลิศด้านการแพทย์เฉพาะทาง
                    ตลอดจนสร้างการบูรณาการองค์ความรู้ข้ามสาขา
                    (Transdisciplinary) ในด้านการให้บริการ การทำวิจัยและนวัตกรรม
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box-custom-column">
            <div class="tab-right">
              <div class="box-card">
                <img
                  src="../assets/image/aboutUs/about5.jpg"
                  class="img-content"
                  alt
                />
                <div class="title-and-detail">
                  <div class="title-card">
                    <p class="number-list">3</p>
                    <!-- สนับสนุนและขยายโอกาส ทางการวิจัยแบบครบวงจร -->
                    To develop medical innovation on service and product
                  </div>
                  <div class="detail-card">
                    <!-- พัฒนาพื้นที่และระบบนิเวศ (EcoSystem) ที่เอื้อต่อการ
                    ศึกษาวิจัยพัฒนา และทดลองนวัตกรรมด้านการแพทย์
                    ภายในย่านให้ได้มาตรฐานและเป็นที่ยอมรับในระดับนานาชาติ
                    พร้อมเป็นศูนย์ กลางการบริการ (Medical Hub)
                    การศึกษาวิจัยพัฒนาและทดลองนวัตกรรม -->
                    การส่งเสริมการพัฒนาการผลิตนวัตกรรมทั้งในแง่การให้บริการและผลิตภัณฑ์
                    โดยมีการนำนวัตกรรมเข้ามาช่วยอำนวยความสะดวกในการบริการและสร้างชิ้นงานให้เกิดมูลค่า
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box-custom-column">
            <div class="tab-left">
              <div class="box-card">
                <img
                  src="../assets/image/aboutUs/about6.jpg"
                  class="img-content"
                  alt
                />
                <div class="title-and-detail">
                  <div class="title-card">
                    <p class="number-list">4</p>
                    To be a health and MedTech startup incubator
                  </div>
                  <div class="detail-card">
                    ช่วยส่งเสริม พัฒนาและบ่มเพาะ สตาร์ตอัพทางการแพทย์
                    เพื่อดึงดูดให้เกิดสตาร์ทอัพรายใหม่
                    และสร้างฐานเศรษฐกิจใหม่ในพื้นที่
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="inside-content">
        <div class="container">
          <el-row :gutter="30">
            <el-col :span="24" :md="12">
              <p class="title-inside">Our Vision</p>
              <p>
                ย่านนวัตกรรมการแพทย์สวนดอก หรือ Suandok Medical Innovation
                District (SMID) มีเป้าหมายในการเป็น
                ‘ศูนย์กลางพัฒนานวัตกรรมด้านการแพทย์และการวิจัย’ ประกอบไปด้วย 2
                เป้าประสงค์ ได้แก่
              </p>
              <div class="our-vision-card">
                <el-card
                  class="box-card"
                  :shadow="'never'"
                  v-for="(dataVision, index) in ourVision"
                  :key="index"
                  @click.native="clickVision(index)"
                  :class="changActive == index ? 'changActive active' : ''"
                >
                  <div class="show-logo-this">
                    <img
                      :src="require(`@/assets/image/icon/${dataVision.icon}`)"
                      alt
                    />
                  </div>
                  <div>
                    <div class="titile-card">
                      {{ dataVision.nameTH }}
                      <br />
                      {{ dataVision.nameEn }}
                    </div>
                    <div class="detail-card">{{ dataVision.detail }}</div>
                  </div>
                </el-card>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="img-out-container-2">
          <img :src="require(`@/assets/image/aboutUs/${imageVision}`)" alt />
        </div>
      </div>
      <div class="inside-content" style="padding: 60px 0 15px;">
        <div class="container">
          <el-row :gutter="30">
            <el-col :span="24" :md="12">
              <p class="title-inside">Our Mission</p>
              <p>
                ย่านวัตกรรมการแพทย์สวนดอก หรือ Suandok Medical Innovation
                District (SMID) มีพันธกิจสำคัญ 5 ด้านที่
                เป็นทั้งสินทรัพย์ทางกายภาพ (physical assets)
                สินทรัพย์ทางเศรษฐกิจ (economy assets) สินทรัพย์ทาง เครือข่าย
                (networking assets) ได้แก่

                <!-- ย่านนวัตกรรมการแพทย์สวนดอก หรือ Suandok Medical Innovation
                District (SMID) มีเป้าหมายในการเป็น
                ‘ศูนย์กลางพัฒนานวัตกรรมด้านการแพทย์และการวิจัย’ ประกอบไปด้วย 2
                เป้าประสงค์ ได้แก่ -->
              </p>
            </el-col>
            <el-col
              :span="24"
              :md="12"
              v-for="(dataMission, index) in dataMissions"
              :key="index"
            >
              <cardMission :mission="dataMission" :keyValue="index" />
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="inside-content">
        <div class="container">
          <el-row :gutter="30">
            <el-col :span="24" :md="8">
              <div class="about-contact">
                <p class="title-inside">ติดต่อเรา</p>
                <p class="under-contact">ย่านนวัตกรรมการแพทย์สวนดอก</p>
                <p class="address">
                  110 ถ.อินทวโรรส ต.ศรีภูมิ อ.เมือง จ.เชียงใหม่ 50200
                </p>
                <p>096-0619394</p>
                <p class="email">smid.info1@gmail.com</p>
                <div class="box-social">
                  <button class="btn btn-social">
                    <a
                      href="https://www.facebook.com/SMIDofficial/"
                      target="_blank"
                    >
                      <img src="../assets/image/icon/facebook.svg" alt />
                    </a>
                  </button>
                  <button class="btn btn-social">
                    <a
                      href="https://www.facebook.com/SMIDofficial/"
                      target="_blank"
                    >
                      <img src="../assets/image/icon/messenge.svg" alt />
                    </a>
                  </button>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="img-out-container-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7554.485546814382!2d98.96858075125468!3d18.787331079432896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30da3a86841caa53%3A0xcdf7c4a284655f0e!2z4LmC4Lij4LiH4Lie4Lii4Liy4Lia4Liy4Lil4Lih4Lir4Liy4Lij4Liy4LiK4LiZ4LiE4Lij4LmA4LiK4Li14Lii4LiH4LmD4Lir4Lih4LmI!5e0!3m2!1sth!2sth!4v1597374480532!5m2!1sth!2sth"
            frameborder="0"
            style="border: 0"
            allowfullscreen
            aria-hidden="false"
            tabindex="0"
            class="set-iframe-map"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scope>
  .border20 {
    border-radius: 20px;
    box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.3);
  }
</style>
<script>
import cardMission from "@/components/cardMission.vue";
export default {
  data() {
    return {
      arr1: [],
      arr2: [],
      last_element: [],
      changActive: "",
      imageVision: "about8.jpg",
      ourVision: [
        {
          id: 1,
          icon: "welfare.svg",
          nameTH: "การให้บริการการแพทย์สาธารณะ",
          nameEn: "(public welfare services)",
          detail:
            " การพัฒนาเทคโนโลยีสารสนเทศเพื่อเป็นสื่อกลางในการให้บริการข้อมูล(data service platform)และการเป็นศูนย์กลางการให้บริการทางการแพทย์ (medicalservice center)",
          image: "about8.jpg",
        },
        {
          id: 2,
          icon: "innovation.svg",
          nameTH: "การลงทุนเพื่อสนับสนุนนวัตกรรมและการวิจัย",
          nameEn: "(innovation investments)",
          detail:
            "การพัฒนาศูนย์กลางการวิจัยและพัฒนาธุรกิจสตาร์ทอัพที่เกี่ยวข้องทางการแพทย์และสุขภาพ (medicalresearch & start up)และการพัฒนาโครงสร้างพื้นฐานและการใช้งานที่หลากหลาย (mixeduse)",
          image: "showregis1.png",
        },
      ],
      dataMissions: [
        {
          icon: "microscop.svg",
          titleName:
            "พื้นที่สำหรับการวิจัย พัฒนา และทดลองนวัตกรรม (innovation research and development)",
          subname:
            "เป็นสินทรัพย์ทางกายภาพ (physical assets) และสินทรัพย์ทางเศรษฐกิจ (economy assets)",
        },
        {
          icon: "physics.svg",
          titleName:
            "ผลกระทบเชิงเศรษฐกิจจากการพัฒนานวัตกรรม (income from innovation development)",
          subname:
            "เป็นสินทรัพย์ทางกายภาพ (physical assets) และสินทรัพย์ ทางเครือข่าย (networking assets)",
        },
        {
          icon: "profit.svg",
          titleName:
            "มาตรการและนโยบายสนับสนุนการลงทุนนวัตกรรม (innovation for medical devices and pharmaceuticals)",
          subname: "เป็นสินทรัพย์ทางเศรษฐกิจ (economy assets)",
        },
        {
          icon: "handshake.svg",
          titleName:
            "เครือข่ายความร่วมมือระหว่างหน่วยงาน (information and collaboration linkage)",
          subname: "เป็นสินทรัพย์ทางเศรษฐกิจ (economy assets)",
        },
        {
          icon: "data.svg",
          titleName:
            "ฐานข้อมูลเพื่อการบริการด้านการแพทย์ (interactive with big data)",
          subname: "เป็นสินทรัพย์ทางเศรษฐกิจ (economy assets)",
        },
      ],
    };
  },
  components: {
    cardMission,
  },
  methods: {
    clickVision(index) {
      let data = this.ourVision[index];
      this.changActive = data.id - 1;
      // this.ourVision.splice(index, 1);
      // this.ourVision.unshift(data);
      this.imageVision = data.image;
    },
  },
};
</script>
