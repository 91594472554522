<template>
  <div class="our-mission-card" :class="(keyValue%2)==1 ?'right':'left'">
    <div class="show-logo-this">
      <img :src="require(`@/assets/image/icon/${mission.icon}`)" />
    </div>
    <div>
      <div class="titile-card">
        {{mission.titleName}}
        <br />
      </div>
      <div class="detail-card">{{mission.subname}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    // console.log(this.data1);
  },
  props: ["mission", "keyValue", "data1"],
};
</script>